import React, { useState } from 'react'
import Layout from 'components/Layout'
import { Section, Row, Col } from 'components/grid'
// @ts-ignore
import Fade from 'react-reveal/Fade'
import LargeForm from 'components/forms/LargeForm'
import TimelineCard from 'components/literacy-operating-system/TimelineCard'
// @ts-ignore
import Slide from 'react-reveal/Slide'
import SectionTitle from '../components/global/SectionTitle'
import Modal from '../components/global/Modal'
import OverlayVideo from '../components/overlay-video'
import VideoEmbed from '../components/global/VideoEmbed'
import Resource from 'components/global/Resource'
import { H1, H3 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'

export default function LiteracyOperatingSystemPage() {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [modalContent, setModalContent] = useState({ type: '', title: '' })

	const handleClose = () => {
		setIsModalOpen(false)
	}

	const handleModalContent = (type: string, title: string) => {
		setModalContent({ type, title })
		setIsModalOpen(true)
	}

	return (
		<Layout pageName="LOS">
			<Section>
				<div className="absolute top-0 left-0 h-full w-full bg-cover bg-center" style={{ backgroundImage: `url('/images/literacy-operating-system/book-shelves.jpg')` }}>
					<div className="white-transparent-gradient h-full w-full"></div>
				</div>
				<Row center className="relative pt-48 ">
					<Col width="w-full">
						<H1 className="mx-auto mb-3 max-w-4xl text-center">From High-Quality Curriculum to High-Performing Systems</H1>
						<P className="mx-auto max-w-3xl text-center">
							Partner with American Reading Company to implement a Literacy Operating System that puts high-quality curriculum, leadership learning, and a sustained
							commitment to academic success for every student at its core.
						</P>
					</Col>
				</Row>
			</Section>
			<Section>
				<Row className="my-8">
					<Col width="w-full lg:w-3/4" className=" lg:mx-auto">
						<div className="mb-28">
							<VideoEmbed lazy={false} round src="https://player.vimeo.com/video/683871499" title="every student" />
						</div>
						<div className="mb-14">
							<div className="relative">
								<svg xmlns="http://www.w3.org/2000/svg" className="absolute -top-10 left-0 z-10 h-20" viewBox="0 0 130.51 115">
									<path
										d="m43.65 0 16.26 19.53Q41 38.43 36 45.76a62.39 62.39 0 0 0-7.91 17.88 19.6 19.6 0 0 1 5.49-3.23 16.16 16.16 0 0 1 5.63-1q8.27 0 14.47 7.56a27.88 27.88 0 0 1 6.2 18.27q0 12.6-7.91 21.19a25.31 25.31 0 0 1-19.3 8.57 28.34 28.34 0 0 1-16.19-5.28A35.26 35.26 0 0 1 4.36 95.23 49.51 49.51 0 0 1 0 73.88q0-20 12.05-39.07A143.1 143.1 0 0 1 43.65 0m70.49 0 16.22 19.53q-18.78 18.9-23.76 26.23a61.23 61.23 0 0 0-8 17.88 19.41 19.41 0 0 1 5.48-3.23 16.1 16.1 0 0 1 5.62-1q8.25 0 14.51 7.56a27.72 27.72 0 0 1 6.26 18.27q0 12.6-8 21.19a25.44 25.44 0 0 1-19.29 8.57A28.29 28.29 0 0 1 87 109.72a35.19 35.19 0 0 1-12.06-14.49 49.52 49.52 0 0 1-4.33-21.35q0-20 12-39.07A142.78 142.78 0 0 1 114.14 0"
										fill="#f3f4f6"
									/>
								</svg>
								<P className="relative z-20 text-center italic">
									If we want to make the education system better, we could try to concoct the perfect intervention—the new curriculum, the new model—and hope for
									the best. Or we could [choose a] solution that's equipped with so many built-in feedback loops that it can't help but get better over time.{' '}
									<span className="font-medium text-slate-700">The second option is the one that systems thinkers would endorse.</span>
								</P>
								<svg xmlns="http://www.w3.org/2000/svg" className="absolute -bottom-10 right-0 z-10 h-20 rotate-180 transform lg:bottom-0" viewBox="0 0 130.51 115">
									<path
										d="m43.65 0 16.26 19.53Q41 38.43 36 45.76a62.39 62.39 0 0 0-7.91 17.88 19.6 19.6 0 0 1 5.49-3.23 16.16 16.16 0 0 1 5.63-1q8.27 0 14.47 7.56a27.88 27.88 0 0 1 6.2 18.27q0 12.6-7.91 21.19a25.31 25.31 0 0 1-19.3 8.57 28.34 28.34 0 0 1-16.19-5.28A35.26 35.26 0 0 1 4.36 95.23 49.51 49.51 0 0 1 0 73.88q0-20 12.05-39.07A143.1 143.1 0 0 1 43.65 0m70.49 0 16.22 19.53q-18.78 18.9-23.76 26.23a61.23 61.23 0 0 0-8 17.88 19.41 19.41 0 0 1 5.48-3.23 16.1 16.1 0 0 1 5.62-1q8.25 0 14.51 7.56a27.72 27.72 0 0 1 6.26 18.27q0 12.6-8 21.19a25.44 25.44 0 0 1-19.29 8.57A28.29 28.29 0 0 1 87 109.72a35.19 35.19 0 0 1-12.06-14.49 49.52 49.52 0 0 1-4.33-21.35q0-20 12-39.07A142.78 142.78 0 0 1 114.14 0"
										fill="#f3f4f6"
									/>
								</svg>
							</div>
							<div className="mx-auto my-6 w-32 border-b-4 border-b-ab-100"></div>
							<P shade="darkest" className="text-center font-semibold">
								Dan Heath
							</P>
						</div>
					</Col>
				</Row>
				<Section>
					<div className="mx-4 border-[10px] border-ab-100 py-8 text-center lg:mx-0">
						<H3 className="mb-2 text-ab-100">Use data every day to predict end-of-year results.</H3>
						<P shade="darker">Nurture one coherent system from classroom to boardroom.</P>
					</div>
				</Section>
				<Slide bottom>
					<TimelineCard
						year="1"
						focus="Implement"
						focusText="Establish a high-quality implementation of ARC Core across all classrooms."
						list={[
							<ul className="list-disc">
								<li className="relative mb-1 max-w-max">
									<button onClick={() => handleModalContent('video', 'ARC Core Overview')}>
										<span className="flex items-center gap-2 font-semibold">
											ARC Core
											<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1.5}>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													fill="currentColor"
													d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
												/>
												<path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
											</svg>
										</span>
									</button>
									<img
										src="/images/utils/arrow.svg"
										alt="arrow"
										className="absolute hidden h-16 lg:block"
										style={{ transform: 'rotate(-30deg)', top: -42, right: -60 }}
									/>
									<button
										onClick={() => handleModalContent('video', 'ARC Core Overview')}
										className="absolute hidden h-16 font-semibold text-ab-100 lg:inline-block"
										style={{ top: -88, right: -98 }}
									>
										Watch It in Action
									</button>
								</li>
								<li className="mb-1">
									<button onClick={() => handleModalContent('image', 'ARC Core Digital Supports')}>
										<span className="flex items-center gap-2 font-semibold">
											ARC Core Digital Supports
											<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1.5}>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													fill="currentColor"
													d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
												/>
												<path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
											</svg>
										</span>
									</button>
								</li>
							</ul>
						]}
						handleModalContent={handleModalContent}
					/>
					<TimelineCard year="2" focus="Refine" focusText="Use the science of improvement to refine implementation." handleModalContent={handleModalContent} />
					<TimelineCard year="3" focus="Scale" focusText="Scale refinements systemwide to ensure success for all students." handleModalContent={handleModalContent} />
					<TimelineCard
						year="4"
						focus="Nurture"
						focusText="Nurture a culture of collaborative innovation that uses data to drive dramatic results."
						handleModalContent={handleModalContent}
					/>
					<TimelineCard
						year="5"
						focus="Innovate"
						focusText="Sustain an innovative, high-performing system of continuous improvement."
						handleModalContent={handleModalContent}
					/>
				</Slide>
			</Section>

			<Section margin="mt-20">
				<Row center className="pb-6">
					<Col width="lg:w-1/2">
						<LargeForm pageName="LOS" />
					</Col>
				</Row>
			</Section>
			<Modal wide title={modalContent.title} isOpen={isModalOpen} handleClose={handleClose}>
				{modalContent.type === 'video' && <OverlayVideo title={modalContent.title} />}
				{modalContent.type === 'gif' && (
					<>
						<P>
							SchoolPace Connect provides school leaders with real-time insights into student data at the school and district levels, with dashboards to track Key
							Metrics and drivers of success, empowering all stakeholders to identify where support and intervention are needed most.
						</P>
						<VideoEmbed title="SchoolPace Connect for Schools" src="https://player.vimeo.com/video/491771258" />
					</>
				)}
				{modalContent.type === 'image' && (
					<Row middle>
						<P className="px-4 text-center">
							ARC Core digital supports, including interactive digital frameworks, thematic eLibraries, and sample pre-recorded lessons or read alouds, are available
							with a SchoolPace Connect subscription.
						</P>
						<Col width="w-full md:w-1/2" className="mb-6 md:mb-0">
							<img src="/images/arc-core/schoolpace-connect-screenshot.png" alt="schoolpace connect screenshot" />
						</Col>
						<Col width="w-full md:w-1/2" className="mb-6 md:mb-0">
							<img src="/images/arc-core/thematic-elibrary.png" alt="elibraries" />
						</Col>
					</Row>
				)}
				{modalContent.type === 'learningLab' && (
					<Row middle>
						<P className="mb-3 px-4">
							ARC Executive Coaches, in collaboration with district leadership, develop literacy leaders to run site-based coaching in their building. Principals and
							Literacy Coaches from across the district work together with targeted support from the ARC Executive Coach in the lab school to create a working model
							with demonstrated impact. Participants collaboratively determine a shared vision of success and are responsible for coaching their own schools.
						</P>
						<Col className="mb-6 md:mb-0">
							<img src="/images/literacy-operating-system/learning-lab.png" alt="learning lab" />
						</Col>
					</Row>
				)}
				{/* {modalContent.type === 'arcUniversity' && (
					<div className="flex flex-col items-center gap-10 md:flex-row">
						<div className="w-full">
							<OverlayVideo title="ARC University" />
						</div>

						<div className="w-auto">
							<Resource title="ARC University" maxHeight={300} />
							<p className="mt-2 mb-0 text-center">Click to view PDF</p>
						</div>
					</div>
				)} */}
			</Modal>
		</Layout>
	)
}
